exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-illustration-index-js": () => import("./../../../src/pages/illustration/index.js" /* webpackChunkName: "component---src-pages-illustration-index-js" */),
  "component---src-pages-illustration-mdx-frontmatter-slug-js-content-file-path-projects-illustration-andiamo-al-mare-index-mdx": () => import("./../../../src/pages/illustration/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/projects/illustration/andiamo-al-mare/index.mdx" /* webpackChunkName: "component---src-pages-illustration-mdx-frontmatter-slug-js-content-file-path-projects-illustration-andiamo-al-mare-index-mdx" */),
  "component---src-pages-illustration-mdx-frontmatter-slug-js-content-file-path-projects-illustration-good-ol-days-index-mdx": () => import("./../../../src/pages/illustration/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/projects/illustration/good-ol-days/index.mdx" /* webpackChunkName: "component---src-pages-illustration-mdx-frontmatter-slug-js-content-file-path-projects-illustration-good-ol-days-index-mdx" */),
  "component---src-pages-illustration-mdx-frontmatter-slug-js-content-file-path-projects-illustration-juline-index-mdx": () => import("./../../../src/pages/illustration/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/projects/illustration/juline/index.mdx" /* webpackChunkName: "component---src-pages-illustration-mdx-frontmatter-slug-js-content-file-path-projects-illustration-juline-index-mdx" */),
  "component---src-pages-illustration-mdx-frontmatter-slug-js-content-file-path-projects-illustration-perseo-index-mdx": () => import("./../../../src/pages/illustration/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/projects/illustration/perseo/index.mdx" /* webpackChunkName: "component---src-pages-illustration-mdx-frontmatter-slug-js-content-file-path-projects-illustration-perseo-index-mdx" */),
  "component---src-pages-illustration-mdx-frontmatter-slug-js-content-file-path-projects-illustration-senza-ritorno-index-mdx": () => import("./../../../src/pages/illustration/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/projects/illustration/senza-ritorno/index.mdx" /* webpackChunkName: "component---src-pages-illustration-mdx-frontmatter-slug-js-content-file-path-projects-illustration-senza-ritorno-index-mdx" */),
  "component---src-pages-illustration-mdx-frontmatter-slug-js-content-file-path-projects-illustration-yee-haw-index-mdx": () => import("./../../../src/pages/illustration/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/projects/illustration/yee-haw/index.mdx" /* webpackChunkName: "component---src-pages-illustration-mdx-frontmatter-slug-js-content-file-path-projects-illustration-yee-haw-index-mdx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

